import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Slider from 'react-slick';
import VideoDrawerItem from './VideoDrawerItem/VideoDrawerItem';
import 'slick-carousel/slick/slick.css';

export default function VideoDrawer({ shows }) {
	const sliderUpdated = () => {
		const activeItems = document.querySelectorAll('.slick-slide');

		// Only active items should be focusable. For aria conformance.
		activeItems.forEach((item) => {
			if (item.getAttribute('aria-hidden') === 'true') {
				item.querySelector('a')?.setAttribute('tabindex', '-1');
			} else {
				item.querySelector('a')?.setAttribute('tabindex', '0');
			}
		});
	};

	useEffect(() => {
		sliderUpdated();
	}, []);

	if (!shows) return null;

	const settings = {
		arrows: true,
		infinite: false,
		mobileFirst: true,
		slidesToShow: 6,
		slidesToScroll: 6,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1366,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 6,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
		afterChange: () => sliderUpdated(),
	};

	return (
		<div className="video-drawer">
			<div className="video-drawer--wrapper">
				<div className="video-drawer--grid">
					<h2 className="video-drawer--title">
						Watch Food Network Shows
					</h2>
					<Slider {...settings}>
						{shows.map((show, index) => <VideoDrawerItem key={show.guid} show={show} index={index} />)}
					</Slider>
				</div>
			</div>
		</div>
	);
}

const NextArrow = ({ className, onClick }) => (
	<button
		className={className}
		onClick={onClick}
		type="button"
	>
		Next
	</button>
);

const PrevArrow = ({ className, onClick }) => (
	<button
		className={className}
		onClick={onClick}
		type="button"
	>
		Prev
	</button>
);

VideoDrawer.propTypes = {
	shows: PropTypes.array,
};

VideoDrawer.defaultProps = {
	shows: null,
};

PrevArrow.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
};

PrevArrow.defaultProps = {
	className: undefined,
	onClick: () => {},
};

NextArrow.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
};

NextArrow.defaultProps = {
	className: undefined,
	onClick: () => {},
};
