import PropTypes from 'prop-types';
import { addTrailingSlash } from '../../../utils/utility';
import foodLogo from '../../../inline-images/fnc-show-vertical.png';
import CorusImage from '../../CorusImage/CorusImage';

const globalTvProd = 'https://watch.globaltv.com';
const globalTvUat = 'https://uat1.watch.globaltv.com';
export default function VideoDrawerItem({ show, ...props }) {
	const globalTvAppUrl = process.env.NODE_ENV !== 'production' ? globalTvUat : globalTvProd;
	const showUrl = `${addTrailingSlash(globalTvAppUrl)}series/${show.guid}`;
	const showImageItem = show?.resources.filter((img) => img.tag === 'show_vertical');
	const showImage = showImageItem?.[0]?.uri || foodLogo?.src;

	return (
		<a
			className="video-item"
			href={showUrl}
			rel="noopener noreferrer"
			target="_blank"
			title={show.label}
			aria-label={`Link to ${show.label} show`}
			draggable="false"
			{...props}
		>
			<div className="video-item-image-container">
				<CorusImage
					src={showImage}
					alt={show.label}
					width={378}
					height={555}
				/>
				<div className="overlay" />
			</div>
			<div className="video-item-title">
				{show.label}
			</div>
		</a>
	);
}

VideoDrawerItem.propTypes = {
	show: PropTypes.object.isRequired,
};
