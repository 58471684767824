import React, {
	Fragment, useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Contexts
import AppContext from '../context/AppContext';

// Ads
import Ad from '../components/Ad/Ad';
import { AdSizes } from '../components/Ad/AdSizes';
import OutbrainAd from '../components/Ad/OutbrainAd';

// Analytics
import AdobeAnalyticsDataLayer from '../components/AdobeAnalytics/AdobeAnalyticsDataLayer';

// SEO
import GoogleDataLayerCorus from '../components/GoogleTagManager/GoogleDataLayerCorus';
import SeoGoogleSitelinkSearchBox from '../components/Seo/Json/SeoGoogleSitelinkSearchBox';
import SeoYoast from '../components/Seo/SeoYoast';

// Visual Components
import FeaturedImage from '../components/Homepage/FeaturedImage/FeaturedImage';
import Heading from '../components/Text/Heading';
import HomepagePromo from '../components/Homepage/Promo/Promo';
import PostBlockGrid from '../components/PostBlock/PostBlockGrid';
import VideoDrawer from '../components/VideoDrawer/VideoDrawer';
import Newsletter from '../components/Newsletter/Newsletter';

// Data Fetching
import fetchShows from '../components/VideoDrawer/fetchShows';
import { HOMEPAGE_QUERY } from '../components/Homepage/HomepageQuery';

// Utilities
import { initializeApollo } from '../utils/apolloClient';
import { fetchSeoMeta } from '../utils/fetchSeoMeta';
import { isUsTraffic } from '../utils/geoBlock';

// Global Data
import globalValues from '../static-data/global-values.json';

const isOutbrainEnabled = globalValues?.data?.corusAdsSettings?.ceAdsOutbrainEnabled;

const outbrainWidgetId = globalValues?.data?.corusAdsSettings?.ceAdsOutbrainHomepageWidgetId;

/**
 * @summary Renders a Homepage ad slot.
 * @param {object} props Props
 * @param {number} props.count Ad slot count
 * @returns {JSX.Element}
 */
function AdSlot({ count }) {
	return (
		<Ad
			key={`homepage-ad-${count}`}
			sizes={{
				'xs sm': AdSizes.BIG_BOX,
				md: AdSizes.LEADERBOARD,
				lg: AdSizes.BIG_BOX,
				'xl xxl': AdSizes.LEADERBOARD,
			}}
			htmlId={`homepage-ad-${count}`}
			pos={`${count + 1}`}
		/>
	);
}

AdSlot.propTypes = {
	count: PropTypes.number.isRequired,
};

function RedirectedMessage({ handleRedirectedMessageButtonClose }) {
	return (
		<div className="redirected-message">
			<div className="redirected-message-container">
				<span className="redirected-message-text">Sorry! The content you tried to view is not available in your country.</span>
			</div>
			<button
				className="redirected-message-close"
				type="button"
				aria-label="Close this message (click or tab here and press any key)"
				onClick={handleRedirectedMessageButtonClose}
				onKeyDown={handleRedirectedMessageButtonClose}
			>
				&nbsp;
			</button>
		</div>
	);
}

RedirectedMessage.propTypes = {
	handleRedirectedMessageButtonClose: PropTypes.func.isRequired,
};

function HomepageTagSectionContainer({ name, slug, posts }) {
	const seeMoreLabel = `See more from ${name}`;

	return (
		<div className="homepage-tag-sections-container">
			<div className="tag-section">
				{name && (
					<Heading
						as="h2"
						className="tag-section--title"
					>
						{name}
					</Heading>
				)}
				<PostBlockGrid posts={posts} />
				<div className="see-more-container">
					<Link href={`/tag/${slug}`} aria-label={seeMoreLabel} className="see-more">

						{seeMoreLabel}

					</Link>
				</div>
			</div>
		</div>
	);
}

HomepageTagSectionContainer.propTypes = {
	name: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	posts: PropTypes.array.isRequired, // Needs a more specific prop type
};

/**
 * @summary The Homepage.
 * @param {object} props Props
 * @returns {JSX.Element}
 */
export default function Home({
	homepageFeature, pageSeo, promo, promoSecondary, sections, shows, geoBlocked,
}) {
	// For the homepage, `hostUrl` is `https://foodnetwork.ca`.
	const { hostUrl } = useContext(AppContext);
	const [showRedirectMessage, setShowRedirectMessage] = useState(geoBlocked === true);

	const closeRedirectMessage = () => {
		setShowRedirectMessage(false);
	};

	let adCount = 1;

	// In case a geo-blocked post is clicked on the homepage,
	// the page might be shallow routed, and server-side code won't be run.
	// Therefore, useEffect() has to be used to show the redirect message.
	useEffect(() => {
		setShowRedirectMessage(geoBlocked === true);
	}, [geoBlocked]);

	return (
		<>
			<GoogleDataLayerCorus
				id={pageSeo?.id}
				category="Food Network Canada"
				isLanding="true"
				pageTitle="Food Network Canada"
				type="Page"
				section1="Homepage"
			/>
			<AdobeAnalyticsDataLayer
				viewName="home"
				viewType="homepage"
			/>
			<SeoGoogleSitelinkSearchBox siteUrl={hostUrl} />
			<SeoYoast seo={pageSeo?.seo} />

			{/* US Content geo-blocking message */}
			{showRedirectMessage && (
				<RedirectedMessage handleRedirectedMessageButtonClose={() => closeRedirectMessage()} />
			)}

			{/* Homepage Content */}
			<div className="homepage">
				{/* Homepage Feature */}
				{homepageFeature && <FeaturedImage settings={homepageFeature} />}

				{/* Latest Content */}
				<div className="homepage-newest-content-container">
					{sections?.newest && Object.values(sections.newest).map((section) => (
						<Fragment key="newest posts">
							<PostBlockGrid posts={section} />
						</Fragment>
					))}
				</div>

				<VideoDrawer shows={shows} />

				<AdSlot count={adCount} />

				{/* Tag Sections */}
				{sections?.tags && Object.values(sections.tags).map((section, index) => {
					adCount += 1;

					const sectionName = section[0]?.tagInfo?.name;
					const sectionSlug = section[0]?.tagInfo?.slug;

					return (
						<Fragment key={sectionSlug}>
							<HomepageTagSectionContainer
								name={sectionName}
								slug={sectionSlug}
								posts={section}
							/>

							{/* Newsletter signup and promo - appears only after first section */}
							{(index === 0) && <Newsletter />}
							{(index === 0) && <HomepagePromo promo={promo} />}

							{/* Insert an ad after each section, unless it is the last section before the footer */}
							{Object.keys(sections.tags).length !== (index + 1) && (
								<AdSlot count={adCount} />
							)}
						</Fragment>
					);
				})}

				<HomepagePromo promo={promoSecondary} />

				{/* Outbrain Widget */}
				{isOutbrainEnabled && (
					<div className="homepage-tag-sections-container">
						<OutbrainAd src={hostUrl} widgetId={outbrainWidgetId} />
					</div>
				)}
			</div>
			{/* End Homepage Content */}
		</>
	);
}

export async function getServerSideProps(context) {
	const apolloClient = initializeApollo();
	const geoBlocked = context.query?.redirected === 'true';

	// Fetch Page Yoast SEO Meta
	const pageSeo = await fetchSeoMeta('/');

	// Homepage Settings Query
	const {
		data: {
			homepageSettings,
			homepageFeature,
			tagSections: { posts },
		},
	} = await apolloClient.query({
		query: HOMEPAGE_QUERY,
		variables: {
			excludeUS: isUsTraffic(context), // Hide US content from US visitors
		},
	});

	const tags = {};
	const newest = {};
	// Format payload
	if (posts) {
		const m = [...new Set(posts.map((post) => post?.tagInfo?.slug))];
		m.forEach((slug) => {
			if (slug) {
				tags[slug] = posts.filter((post) => post?.tagInfo?.slug === slug);
			} else {
				newest.posts = posts.filter((post) => post?.tagInfo?.slug === slug);
			}
		});
	}

	// Fetch shows for Video Drawer
	let shows = await fetchShows();

	// Hide US shows from US visitors
	if (isUsTraffic(context)) {
		shows = shows.filter((show) => show.data?.country_of_origin !== 'US');
	}

	return {
		props: {
			homepageFeature,
			pageSeo,
			promo: homepageSettings?.homepagePromoSettings,
			promoSecondary: homepageSettings?.homepageSecondaryPromoSettings,
			sections: { tags, newest },
			shows: shows?.length > 0 ? shows : null,
			geoBlocked,
		},
	};
}

Home.propTypes = {
	homepageFeature: PropTypes.shape({
		desc: PropTypes.string,
		link: PropTypes.string,
		imageUrl: PropTypes.string,
		tag: PropTypes.shape({
			slug: PropTypes.string,
			name: PropTypes.string,
		}),
		title: PropTypes.string,
	}).isRequired,
	pageSeo: PropTypes.shape({
		databaseId: PropTypes.number,
		id: PropTypes.string,
		seo: PropTypes.object,
	}),
	promo: PropTypes.object.isRequired,
	promoSecondary: PropTypes.object.isRequired,
	sections: PropTypes.object.isRequired,
	shows: PropTypes.array,
	geoBlocked: PropTypes.bool,
};

Home.defaultProps = {
	pageSeo: {
		databaseId: '',
		id: '',
		seo: {},
	},
	shows: [],
	geoBlocked: false,
};
